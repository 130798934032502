import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors } from '../../../styles/theme';
import { pagination } from '../../../helpers/pagination';
import { Arrow } from 'components';

const PageNumber = styled.button`
  color: #61737a;
  display: inline-block;
  text-align: center;
  width: ${({ isEllipses }) => (isEllipses ? '15px;' : '35px;')};
  font-size: 1.5rem;
  cursor: ${({ isEllipses }) => (isEllipses ? 'default' : 'pointer')};
  border: none;
  background: none;
  padding: 0;
`;

const ActivePageNumber = styled(PageNumber)`
  color: #203a45;
  font-weight: 600;
  border-bottom: 5px solid ${colors.accentColor};
  border-radius: 0;
  padding-bottom: 2rem;
`;

const PaginateList = styled.ul`
  line-height: 4;
  text-align: center;
  position: relative;
  width: 100%;
`;

const Paginate = ({ goToPage, isLoading, limit, page, total }) => {
  let lastPage = Math.ceil(total / limit);
  let pageNumbers = pagination(page, lastPage);

  const renderPageNumbers = pageNumbers.map((number, index) => {
    const isEllipses = typeof number === 'string';
    const props = {
      key: `number-${index}`,
      // if the 'number' is '...' we remove the onClick with null
      onClick: !isEllipses ? () => goToPage(number) : () => {}
    };

    const isActivePage = number === page;
    if (isActivePage) {
      return (
        <ActivePageNumber {...props} aria-label={`Current Page, ${number} of ${lastPage}`}>
          {number}
        </ActivePageNumber>
      );
    } else {
      return (
        <PageNumber
          {...props}
          disabled={isLoading}
          tabIndex={isEllipses ? '-1' : ''}
          aria-label={isEllipses ? '' : `Page ${number} of ${lastPage}`}
          isEllipses={isEllipses}
          data-cy={`pagination-button-${number}`}
        >
          {number}
        </PageNumber>
      );
    }
  });
  return (
    <PaginateList>
      {page !== 1 && (
        <Arrow
          data-cy="paginate-previous"
          disabled={isLoading}
          type="back"
          aria-label="Previous Page"
          onClick={() => goToPage(page - 1)}
        />
      )}
      {renderPageNumbers}
      {page !== lastPage && (
        <Arrow
          data-cy="paginate-next"
          disabled={isLoading}
          type="forward"
          aria-label="Next Page"
          onClick={() => goToPage(page + 1)}
        />
      )}
    </PaginateList>
  );
};

Paginate.propTypes = {
  goToPage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  limit: PropTypes.number,
  page: PropTypes.number,
  total: PropTypes.number
};

export default Paginate;
